export interface Category extends NamedApiEntity {
  emoji?: string
}

export async function fetchCategories() {
  const {
    attributes: categories,
    pending,
    refresh,
  } = await useApiFetch<Category[]>('/api/v1/categories')
  return { categories, pending, refresh }
}
