export async function useCategories() {
  const allCategories = useState<Category[]>('allCategories', () => [])

  if (!allCategories.value || !allCategories.value.length) {
    const { categories } = await fetchCategories()
    allCategories.value = categories.value ?? []
  }

  return { categories: computed(() => allCategories.value) }
}
